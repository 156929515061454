// @ts-nocheck
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import React, { Suspense, lazy } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";
import { Root } from "../frameworks/layout/index";
import Loader from "../frameworks/layout/component/common/loader/loader";
import { useAuth } from "../services/auth-service/auth.provider";

const { isAuthenticated } = useAuth();

// const HomeScreen = lazy(() => import("../screens/home"));
const LandingScreen = lazy(() => import("../screens/Landing"));
const LoginScreen = lazy(() => import("../screens/Login"));
const ForgotPasswordScreen = lazy(() => import("../screens/ForgotPassword"));
const ResetPasswordScreen = lazy(() => import("../screens/ResetPassword"));

const RouterConfig = (props) => (
  <Switch>
    <PrivateRoute path="/dashboard" component={Root} />
    <Route path="/login" component={LoginScreen} />
    <Route path="/forgotpassword" component={ForgotPasswordScreen} />
    <Route path="/resetpassword" component={ResetPasswordScreen} />
    <Route path="/" component={LandingScreen} />
  </Switch>
);

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: `/login`,
          }}
        />
      )
    }
  />
);

function RouteWithSubRoutes(route) {
  return (
    <Route
      path={route.path}
      render={(props) => (
        // pass the sub-routes down to keep nesting
        <route.component {...props} routes={route.routes} />
      )}
    />
  );
}

const Stack = createStackNavigator();

const AppNavigatorInternal = (): JSX.Element => {
  return (
    <NavigationContainer>
      <Stack.Navigator headerMode={"none"}>
        {/* <Stack.Screen name="VerifierScreen" component={VerifierScreen} /> */}
      </Stack.Navigator>
    </NavigationContainer>
  );
};

export const AppNavigator = (): JSX.Element => {
  return (
    <Router>
      <Suspense fallback={<Loader />}>
        <RouterConfig />
      </Suspense>
    </Router>
  );
};
