//@ts-nocheck
import React, { Fragment } from "react";
import Loader from "./component/common/loader/loader";
import Header from "./component/common/header/header";
import HeaderSM from "./component/common/header/headerSM";
import Sidebar from "./component/common/sidebar/sidebar";
import Rightsidebar from "./component/common/sidebar/rightsidebar";
import Footer from "./component/common/footer/footer";
import ThemeCustomize from "./component/common/theme-customizer/themeCustomize";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useGetNavitem } from "./appProvider";
import { useViewport, ViewportProvider } from "../../providers/viewport";

const MENU_ITEMS = [
  {
    eventKey: "manufacturer",
    title: "",
    icon: <i class="icon-briefcase"></i>,
    path: "/manufacturer",
    type: "sub",
    active: false,
    bookmark: false,
    children: [{ title: "Manufacturer", type: "sub" }],
  },
  //  {
  //   eventKey:"organization",
  //    title: "",
  //    icon: <i class="icon-home"></i>,
  //    path: "/organization",
  //    type: "sub",
  //    active: true,
  //    bookmark: true,
  //    children: [{ title: "Organization", type: "sub" }],
  //  },
  {
    eventKey: "analytics",
    title: "",
    icon: <i className="icon-bar-chart-alt"></i>,
    path: "/analytics",
    type: "sub",
    active: false,
    children: [{ title: "Analytics", type: "sub" }],
  },
  {
    eventKey: "products",
    title: "",
    icon: <i className="icon-shopping-cart-full"></i>,
    path: "/products",
    type: "sub",
    active: false,
    children: [
      { title: "Products", type: "sub" },
      // {
      //   title: "New Product",
      //   type: "link",
      //   path: `/products/new-product`,
      // },
    ],
  },
  {
    eventKey: "transactions",
    title: "",
    icon: <i className="icon-server"></i>,
    path: "/transactionsdetails",
    type: "sub",
    active: false,
    children: [
      { title: "Transactions", type: "sub" },
      {
        title: "Transactions",
        type: "link",
        path: `/transactions`,
      },
      {
        title: "Brand Protection",
        type: "link",
        path: `/transactions/brandprotection`,
      },
    ],
  },
  // {
  //   eventKey: "campaigns",
  //   title: "",
  //   icon: <i className="icon-announcement"></i>,
  //   path: "/campaigns",
  //   type: "sub",
  //   active: false,
  //   children: [
  //     { title: "Campaigns", type: "sub" },
  //     // {
  //     //   title: "New Campaign",
  //     //   type: "link",
  //     //   path: `/campaigns/new-campaign`,
  //     // },
  //   ],
  // },
  // {
  //   title: "",
  //   icon: <i class="icon-layers-alt"></i>,
  //   path: "/associatedorganisations",
  //   type: "sub",
  //   active: false,
  //   children: [{ title: "Associated Organizations", type: "sub" }],
  // },
  // {
  //   eventKey: "personas",
  //   title: "",
  //   icon: <i className="icofont icofont-business-man-alt-2"></i>,
  //   path: "/personas",
  //   type: "sub",
  //   active: false,
  //   children: [
  //     { title: "Personas", type: "sub" },
  //     {
  //       title: "Persona Templates",
  //       type: "link",
  //       path: "/personas/template",
  //     },
  //     {
  //       title: "Persona Table",
  //       type: "link",
  //       path: "/personas/table",
  //     },
  //   ],
  // },
  // {
  //   eventKey: "provenance",
  //   title: "",
  //   icon: <i className="icofont icofont-map-pins"></i>,
  //   path: "/provenance",
  //   type: "sub",
  //   active: false,
  //   children: [
  //     { title: "Touch Points", type: "sub" },
  //     {
  //       title: "Manage Templates",
  //       type: "link",
  //       path: `/provenance/manage-templates`,
  //     },
  //     {
  //       title: "New Template",
  //       type: "link",
  //       path: `/provenance/new-template`,
  //     },
  //     {
  //       title: "New Provenance",
  //       type: "link",
  //       path: `/provenance/association`,
  //     },
  //   ],
  // },
  {
    eventKey: "feedback",
    title: "",
    icon: <i className="icofont icofont-ui-rating"></i>,
    path: "/feedback",
    type: "sub",
    active: false,
    children: [{ title: "Feedback", type: "sub" }],
  },
  {
    eventKey: "qrBatch",
    title: "",
    icon: <i className="icofont icofont-qr-code"></i>,
    path: "/qr-batches",
    type: "sub",
    active: false,
    children: [
      { title: "QR Collections", type: "sub" },
      {
        title: "Existing QR Collections",
        type: "link",
        path: `/qr-batches`,
      },
      {
        title: "Create New QR Collection",
        type: "link",
        path: `/new-qr-batch`,
      },
      {
        title: "QR-Product Associations",
        type: "link",
        path: `/qr-ranges`,
      },     
      {
        title: "Create QR-Product Association",
        type: "link",
        path: `/new-qr-range`,
      },
    ],
  },
];

const App = ({ children }) => {
  const { context, getNavitemMachine, state } = useGetNavitem();
  const { bp, width } = useViewport();

  console.log("navitem", context?.payload?.navItems);

  let _sideBarItems = [];
  Array.isArray(MENU_ITEMS) &&
    MENU_ITEMS.map((item) => {
      let _tempNav;
      context?.payload?.navItems?.map((ele) => {
        if (item?.eventKey === ele?.key && ele?.enable) {
          _sideBarItems.push({
            ...item,
          });
        }
      });
    });

  const getHeader = () => {
    if (bp === "xs" || bp == "sm") {
      return <HeaderSM menuItems={MENU_ITEMS} searchbar={false} />;
    } else {
      return <Header menuItems={MENU_ITEMS} searchbar={false} />;
    }
  };

  console.log(`sideBarItems`, _sideBarItems);
  return (
    <Fragment>
      <Loader />
      <div className="page-wrapper">
        <div className="page-body-wrapper">
          {getHeader()}
          <Sidebar menuItems={MENU_ITEMS} />
          <Rightsidebar />
          <div className="page-body">{children}</div>
          <Footer />
          {/* <ThemeCustomize/> */}
        </div>
      </div>
      <ToastContainer
        autoClose={2000}
        position="top-center"
        className="toast-container"
        toastClassName="dark-toast"
      />
    </Fragment>
  );
};

export default App;
